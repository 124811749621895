import { graphql } from 'gatsby'
import Collection from '../containers/Collection'

export default Collection

export const pageQuery = graphql`
  query allArticleByCategoryQuery($skip: Int!, $limit: Int!, $slug: String!) {
    collectionInfo: articleCategory(slug: { eq: $slug }) {
      id
      name
      seoTitle
      seoDescription
      slug
      picture {
        fluid (maxWidth: 1600){
          src
        }
        fixed(width: 1600, height: 650, cropFocus: CENTER, quality: 100) 
        {
        src
        width
        height
        }
      }
      description
      showLanding
    }

    posts: allArticle(
      filter: { private: { ne: true }, category: { slug: { eq: $slug } }}
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
      ...ArticlePagination
    }
  }
`
