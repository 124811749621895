import React, { useContext } from 'react'
import pageContextProvider from '@helpers/pageContextProvider'
import { Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const Collection = ({ data: { posts, collectionInfo } }) => {
  const context = useContext(pageContextProvider)

  const { pageContext: { services = {}, siteUrl, slug } = {}}= context

  const styles = {
    parallax : {
      backgroundAttachment: `fixed`,
      backgroundPosition:`top`,
      backgroundSize: `auto`,
      backgroundRepeat: `repeat-x`,
      minHeight: `400px`,
      maxWidth:`100%`,
    }
  }

  const backgroundImage = collectionInfo.picture ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.15)), url(${collectionInfo.picture.fluid.src})` : ''

  return (
    <>
      <Seo 
        title={collectionInfo.name+' | Comment devenir rentier'} 
        description={collectionInfo.description} 
        seoDescription={collectionInfo.seoDescription} 
        seoTitle={collectionInfo.seoTitle}
        siteUrl={siteUrl+slug} 
      />

      <Main style={{...styles.parallax, backgroundImage: backgroundImage }}>
         <Stack>
            <Main sx={{py:`5rem`}}>
              <PageTitle
                header={collectionInfo.name}
                subheader={collectionInfo.title}
                running={collectionInfo.description}
                color={backgroundImage ? "white" : 'omegaDark'}
              />
            </Main>
         </Stack>
      </Main>

      <Divider />
      <Main sx={{px:`1rem`}}>
        {posts.nodes && (
          <CardList
            nodes={posts.nodes}
            variant={['horizontal-md', 'vertical']}
            columns={[1, 2, 3, 3]}
            titleLink='auteur/gael-deballe/'
            omitCategory={
              context.pageContext &&
              context.pageContext.collectionType === 'category'
            }
            omitFooter
          />
        )}
      </Main>
      <Divider />
      <PreFooter>
        <Pagination {...posts.pageInfo} {...collectionInfo} />
      </PreFooter>
    </>
  )
}
export default Collection
